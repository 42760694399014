<template>
  <div>
    <el-card>
      <el-row :gutter="20" class="left">
        <el-col :span="24">
          <i class="el-icon-arrow-left" style="color: #999; cursor: pointer" @click="back">
            订单详情
          </i>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <div class="warning">
        <p>{{detail.order_status_name}}</p>
      </div>
      <el-timeline class="left top">
        <el-timeline-item
          class="timeline-item"
          v-for="(item, index) in detail.progress_list"
          :key="index"
          color="rgb(64,158,255)"
          :timestamp="item.time">
          {{item.name}}
          <div class="timelines" v-show="item.img_path" >
            <img :src="item.img_path" alt="">
            <div @click="vbs(item.img_path)" class="openPreview"><i class="el-icon-zoom-in"></i></div>
          </div>
          <span style="font-size:12px;color:#666">{{item.address}}</span>
        </el-timeline-item>
      </el-timeline>
      <div class="info">
        <p>用户信息</p>
      </div>
      <el-form label-width="120px" class="left">
      	<el-row>
					<el-col :span="8">
						<el-form-item label="用户ID:"><div>{{detail.user_id}}</div></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="下单人:"><div>{{detail.consignee}}</div></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="联系方式:"><div>{{detail.mobile}}</div></el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="服务地址:"><div>{{detail.address}}</div></el-form-item>
					</el-col>	
				</el-row>
      </el-form>
      <div class="info">
        <p>技师信息</p>
      </div>
      <el-form label-width="120px" class="left margin_left">
        <el-form-item label="技师:"><div>{{detail.jishi_info.jishi_name}}</div></el-form-item>
        <el-form-item label="技师头像:">
          <el-image
          style="width: 120px; height: 120px;"
            :src="detail.jishi_info.gzxxz_img"
            fit="fill">
          </el-image>
        </el-form-item>
      </el-form>
      <div class="info">
        <p>订单信息</p>
      </div>
      <el-form label-width="120px" class="left">
      	<el-row>
					<el-col :span="8">
						<el-form-item label="系统订单号:"><div>{{detail.order_sn}}</div></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="下单时间:"><div>{{detail.add_time}}</div></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="服务时间:"><div>{{detail.service_start_time}}</div></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="服务时长:"><div>{{detail.service_total_time}}分钟</div></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="出行费用:"><div>￥{{detail.car_fee}}</div></el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="车费详情:"><div>{{detail.car_str}}</div></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="平台优惠券:"><div>￥{{detail.coupon_fee}}</div></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="技师优惠券:"><div>￥{{detail.jishi_coupon_fee}}</div></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="门店优惠券:"><div>￥{{detail.store_coupon_fee}}</div></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="服务项目费用:"><div>￥{{detail.service_fee}}</div></el-form-item>
					</el-col>
					<el-col :span="8">
						<el-form-item label="实付金额:"><div>￥{{detail.pay_fee}}</div></el-form-item>
					</el-col>
       	</el-row>
      </el-form>
      <div v-for="(item,index) in detail.service_list" :key="index">
      	<div class="info">
	        <p v-if="item.order_type==1">服务内容（加钟）</p>
	        <p v-else-if="item.order_type==2">服务内容（升级）</p>
	        <p v-else>服务内容</p>
	        <p style="text-align: right;">
      			付款单号：{{item.fk_bh}}&nbsp;&nbsp;
      			项目金额：￥{{item.service_fee}}&nbsp;&nbsp;
      			服务时长：{{item.service_time}}分钟&nbsp;&nbsp;
      			支付方式：{{item.pay_name}}&nbsp;&nbsp;
      			支付状态：<span v-if="item.pay_status==2">已支付</span><span v-else>未支付</span>
      		</p>
	      </div>
	      <el-table
	        :data="item.name_list"
	        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
	        style="width: 100%">
	        <el-table-column label="服务项目">
	          <template slot-scope="scope">
	            <div>
	              <img style="width:60px;height:60x" :src="scope.row.service_logo" alt="">
	            </div>
	          </template>
	        </el-table-column>
	        <el-table-column prop="service_name" label="服务名称"></el-table-column>
	        <el-table-column label="服务时长">
	          <template slot-scope="scope">
	            <div>{{scope.row.service_time}}分钟</div>
	          </template>
	        </el-table-column>
	        <el-table-column prop="service_number" label="服务数量"></el-table-column>
	      </el-table>
      </div>
      <div class="left">
        <div class="top">合计数量:{{detail.service_number}}</div>
      </div>
      <div>
				<div class="info">
	        <p>报备记录</p>
	      </div>
				<el-table :data="detail.baobei_list" :header-cell-style="{ background: '#eef1f6', color: '#606266' }" style="width: 100%">
					<el-table-column prop="jishi_name" label="技师名称"></el-table-column>
					<el-table-column prop="service_status" label="服务状态"></el-table-column>
					<el-table-column prop="report_content" label="报备内容"></el-table-column>
					<el-table-column prop="add_time" label="报备时间"></el-table-column>
				</el-table>
			</div>
			<div class="right">
        <el-button class="top" type="primary" @click="back">返回</el-button>
      </div>
      <el-dialog
        title=""
        :visible.sync="previewVisible"
        width="600px">
        <img style="width:100%;height:500px" :src="previewSrc" alt="">
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
let that;
let timer = null;
export default {
  data () {
    return {
    	order_id: 0,
    	detail: {jishi_info:{}},
      previewVisible:false,
      previewSrc:''
    }
  },
  created() {
  	if(!this.$store.state.userInfo) {
			// 防止未登录
			this.$router.push({
				path: '/login'
			})
		} else {
			that = this;
			if(this.$route.query.pkid){
				this.order_id = this.$route.query.pkid;
			}
			this.getOrderDetail();
		}
  },
  methods: {
  	getOrderDetail(){
  		var url = 'order/order_detail';
			let params = {
				order_id: this.order_id
			};
			this.fd_post(url, params).then((res) => {
				if(res.status) {
					console.log(res.detail);
					that.detail = res.detail;
				} else {
					that.$message.error(res.msg);
				}
			}).catch((err) => {
				that.$message.error('网络错误');
			});
  	},
    back(){
      this.$router.go(-1)
    },
    vbs(url){
      this.previewSrc=url
      this.previewVisible=true
    }
  },
}
</script>

<style lang="scss" scoped>
	@import './Order_detail.scss';
</style>